/* eslint-disable @next/next/no-html-link-for-pages */
import { Link } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { GetCookie } from "../provider/common";
import { getReferrer } from "../provider/referrer";
import { useRouter } from "next/router";

const axios = require("axios").default;

export default function Menubar(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [sellerPageState, setSellePageStage] = useState("");
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    isLoginCheck();
    // 현재 판매자 페이지 상태값을 따로 저장한다.
    changeSellerPageState();
  }, []);

  async function isLoginCheck() {
    const isLogin = await GetCookie("isLogin");
    setIsLogin(Number(isLogin));
  }

  const checkApproved = async () => {
    try {
      const approvedApi = process.env.PANDA_API_URL + "/api/auth/sellerDecode";
      const token = await GetCookie("token");
      const approvedRes = await axios.post(
        approvedApi,
        {
          token: token,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      const approvedCondition = approvedRes.data.data;
      if (approvedCondition === "not_login") {
        alert("로그인 후 이용해주세요.");
        return (window.location.href = `/auth/login`);
      } else if (approvedCondition === "submitted") {
        alert("승인 대기중입니다.\n영업일 기준 1일내로 연락드리겠습니다.");
      } else if (approvedCondition === "not_approved") {
        // 로그인 인 경우
        return (window.location.href = "/board/register");
      } else if (approvedCondition === "approved") {
        return (window.location.href = "/seller/dashboard");
      }
    } catch (error) {
      alert("오류가 발생했습니다. 다시 시도해주세요." + e);
    }
  };

  const changeSellerPageState = async () => {
    const approvedApi = process.env.PANDA_API_URL + "/api/auth/sellerDecode";
    const token = await GetCookie("token");
    const approvedRes = await axios.post(
      approvedApi,
      {
        token: token,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    const approvedCondition = approvedRes.data.data;
    if (approvedCondition === "not_login") {
      setSellePageStage("판매자신청");
      setPageName("notLogIn");
    } else if (approvedCondition === "submitted") {
      setSellePageStage("판매자페이지");
      setPageName("submitted");
    } else if (approvedCondition === "not_approved") {
      setSellePageStage("판매자신청");
      setPageName("not_approved");
    } else if (approvedCondition === "approved") {
      setSellePageStage("판매자페이지");
      setPageName("approved");
    }
  };

  return (
    <nav className="menu-bar">
      {isLogin === 1 ? (
        <ul>
          <li>
            <Link href="/parts/all_category">
              {" "}
              <span className="xi-bars"></span>카테고리
            </Link>
          </li>
          <li>
            <Link href="/marketplace/cart">
              <span className="xi-paper-o"></span>견적바구니
            </Link>
          </li>
          <li>
            <Link href="/cart/view">
              <span className="xi-cart-o"></span>장바구니
            </Link>
          </li>

          {pageName === "notLogIn" ? (
            <li>
              <button onClick={checkApproved}>
                <span className="xi-user-o"></span>
                판매자신청
              </button>
            </li>
          ) : pageName === "submitted" ? (
            <li className="buyer-list">
              <button onClick={checkApproved}>
                <span className="xi-user-o"></span>
                판매자 <br />
                페이지
              </button>
            </li>
          ) : pageName === "approved" ? (
            <li className="buyer-list">
              <button onClick={checkApproved}>
                <span className="xi-user-o"></span>
                판매자 <br />
                페이지
              </button>
            </li>
          ) : pageName === "not_approved" ? (
            <li>
              <button onClick={checkApproved}>
                <span className="xi-user-o"></span>
                판매자신청
              </button>
            </li>
          ) : (
            ""
          )}
          <li className="buyer-list">
            <Link href="/mypage/orders">
              <span className="xi-user"></span>마이 <br />
              페이지
            </Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <Link href="/parts/all_category">
              <span className="xi-bars"></span>카테고리
            </Link>
          </li>
          <li>
            <Link href="/estimate/lists">
              <span className="xi-calculator"></span>견적내역
            </Link>
          </li>
          <li>
            <Link href="/cart/view">
              <span className="xi-cart-o"></span>장바구니
            </Link>
          </li>
          <li>
            <Link onClick={checkApproved}>
              <span className="xi-user-o"></span>판매자
              <br /> 페이지
            </Link>
          </li>
          <li>
            <Link href="/auth/register">
              <span className="xi-registered"></span>회원가입
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
}
